import { useRef, useEffect } from 'react'

export default function useCanvas(draw) {
  const canvasRef = useRef()

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')

    let frameCount = 0
    let animationFrameId = null

    const update = () => {
      frameCount++
      draw(context, frameCount)
      animationFrameId = requestAnimationFrame(update)
    }

    update()

    const resizeObserverInstance = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry?.devicePixelContentBoxSize?.[0]) {
          const { inlineSize, blockSize } = entry.devicePixelContentBoxSize[0]
          canvas.width = inlineSize
          canvas.height = blockSize
        }
      }
    })

    resizeObserverInstance.observe(canvas)

    return () => {
      resizeObserverInstance.unobserve(canvas)
      cancelAnimationFrame(animationFrameId)
    }
  }, [draw])

  return canvasRef
}
