export default function stylizedBody() {
    return {
        marks: {
            bold: ({ children }) => {
                return (
                    <span style={{ fontWeight: '500' }}>
                        {children}
                    </span>
                )
            },
        },
        types: {
            block: ({ children }) => {
                return (
                    <span>
                        {children}
                    </span>
                )
            },
        },
    }
}
