import serializers from 'serializers/stylizedHeadline'
import justLinksSerializer from 'serializers/justLinks'
import BlockContent from '@sanity/block-content-to-react'
import Button from 'components/Button'
import Media from 'components/Media'
import Image from 'components/Image'
import Wave from 'components/Wave'
import cx from 'classnames'
import { screens } from 'lib/constants'
import getColorStyle from 'lib/getColorStyle'
import * as analytics from 'lib/analytics'
import { useState } from 'react'
import { isEmailValid, isNullOrEmpty } from 'lib/validate'
import { useMedia } from 'use-media'
import stylizedBody from 'serializers/stylizedBody'
import { useStore } from 'context/globalStore'
import ScrollToHash from 'components/ScrollToHash'
import { useMemo } from 'react'
import KlaviyoSubmit from 'components/Newsletter'
import { useRouter } from 'next/router';

const BG_STYLE = {
  BG_Color: 'bgColor',
  BG_Media: 'bgMedia',
  BG_Gradient: 'bgGradient',
}
const ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
}

export default function PrimaryHero({ module, settings, pageContext }) {
  const { store } = useStore()
  const router = useRouter();
  const countrySpecificHero = module.countrySpecificHeros?.find(
    (x) => x.countryCode === store.countryCode
  )

  let heroFromPromoPreset;
  if ((router.pathname === '/' || router.asPath.includes('/home')) && store.countryCode === 'US') {
    heroFromPromoPreset = pageContext?.promoConfig?.promoPresets?.activePromo?.homePageHero;
  }
  const heroFromPromoPresetForSpecificCountry = heroFromPromoPreset?.countrySpecificHeros?.find(
    (x) => x.countryCode === store.countryCode
  )

  const {
    headImageMedia,
    headImageMediaDesktop,
    headline,
    headlineWrap,
    headlineColor,
    desktopHeadlineColor,
    body,
    bodyColor,
    desktopBodyColor,
    centerBadge,
    layout,
    miniHero,
    miniHeroHeight,
    cta,
    ctaColor,
    ctaFooter,
    ctaFooterColor,
    emailCapture,
    media,
    desktopMedia,
  } = heroFromPromoPresetForSpecificCountry ?? heroFromPromoPreset ?? countrySpecificHero ?? module

  const isScreenMedium = useMedia({ minWidth: screens.m }, null)
  const isScreenLarge = useMedia({ minWidth: screens.l }, null)
  const { imageLoadPriority } = settings
  const bgStyle = layout?.backgroundStyle
  const background = bgStyle === BG_STYLE.BG_Color ? getColorStyle(layout?.background) : null
  const largeMedia = desktopMedia ?? media

  const headlineTextColor =
    isScreenLarge && desktopHeadlineColor
      ? getColorStyle(desktopHeadlineColor)
      : getColorStyle(headlineColor)
  const bodyTextColor =
    isScreenLarge && desktopBodyColor ? getColorStyle(desktopBodyColor) : getColorStyle(bodyColor)

  const { desktopImage, mobileImage } = ((desktop, mobile) => {
    if (!desktop && mobile) return { desktopImage: mobile.image, mobileImage: mobile.image }
    else if (desktop && mobile) return { desktopImage: desktop.image, mobileImage: mobile.image }
    else return {}
  })(headImageMediaDesktop, headImageMedia)

  const headImage = desktopImage && mobileImage && (
    <>
      <div style={{ flexBasis: '100%' }} className="max-w-300 xxl:max-w-400">
        <Image alt="" className="hidden m:block" image={desktopImage} placeholder={false} />
        <Image alt="" className="block m:hidden" image={mobileImage} placeholder={false} />
      </div>
    </>
  )

  const [captureError, setCaptureError] = useState('')
  const [captureComplete, setCaptureComplete] = useState(false)

  const layoutAlignment = useMemo(() => {
    if (isScreenLarge && layout?.fullWidthOnDesktop)
      return layout?.fullWidthDesktopAlignment || layout?.align
    else return layout?.align
  }, [isScreenLarge, layout])

  const validateEmail = (email) => {
    if (captureComplete) {
      return true
    }

    if (isNullOrEmpty(email)) {
      setCaptureError('Email is required')
      return false
    }

    if (!isEmailValid(email)) {
      setCaptureError('Invalid Email Address')
      return false
    }

    setCaptureError('')
    return true
  }

  const headerTag = 'h2'
  const content = (
    <div
      className={cx('w-full l:max-w-750', {
        'justify-center': layoutAlignment === ALIGN.CENTER || captureComplete,
        'mx-auto': !isScreenLarge || (layoutAlignment !== 'left' && layoutAlignment !== 'right'),
        'xxl:mb-60 2xl:mb-130': !miniHero && !miniHeroHeight,
      })}
    >
      <div
        className={cx('flex flex-wrap space-y-15 l:space-y-20', {
          'justify-center text-center': layoutAlignment === ALIGN.CENTER || captureComplete,
          'justify-start text-left': layoutAlignment === ALIGN.LEFT,
          'justify-end text-right': layoutAlignment === 'right',
        })}
      >
        {showBadge()}
        {headImage}
        {headline ? (
          <>
            <div style={{ color: headlineTextColor }} className={cx('block s:hidden font-serif-modern', {
              'max-w-210 s:max-w-full mx-auto': miniHero,
            })}>
              <BlockContent
                blocks={captureComplete ? emailCapture.successHeadline : headline}
                serializers={serializers(headerTag, 28, headlineWrap)}
              />
            </div>
            <div
              style={{ color: headlineTextColor, marginTop: 0 }}
              className="hidden s:block xl:hidden font-serif-modern"
            >
              <BlockContent
                blocks={captureComplete ? emailCapture.successHeadline : headline}
                serializers={serializers(headerTag, 40, headlineWrap)}
              />
            </div>
            <div style={{ color: headlineTextColor, marginTop: 0 }} className="hidden xl:block font-serif-modern">
              <BlockContent
                blocks={captureComplete ? emailCapture.successHeadline : headline}
                serializers={serializers(headerTag, miniHero ? 56 : 55, headlineWrap)}
              />
            </div>
          </>
        ) : null}
        {body || emailCapture?.successBody ? (
          <p
            style={{ color: bodyTextColor, flexBasis: '100%' }}
            className={cx('l:max-w-500', {
              'mx-auto':
                layout?._type === 'fullWidth' && (layoutAlignment === 'center' || !isScreenLarge),
              'mx-auto':
                layout?._type === 'twoUp' &&
                layoutAlignment === 'center' &&
                layout?.fullWidthOnDesktop &&
                isScreenLarge,
              'text-center': captureComplete,
              'text-15 m:text-20': miniHero,
            })}
          >
            {captureComplete ? (
              <BlockContent blocks={emailCapture.successBody} serializers={stylizedBody()} />
            ) : Array.isArray(body) ? (
              <BlockContent blocks={body} serializers={stylizedBody()} />
            ) : body}
          </p>
        ) : null}
      </div>
      {emailCapture?.enabled && !captureComplete ? (
        <div
          className={cx('flex flex-col max-w-275 l:max-w-400 w-full mt-24 l:mt-32', {
            'mx-auto': layoutAlignment === ALIGN.CENTER,
          })}
        >
          <KlaviyoSubmit
            inputPlaceholder={emailCapture.buttonText}
            signupErrorMessage={captureError}
            signupConfirmationMessage=""
            klaviyoListId={emailCapture.mailingListId}
            validator={validateEmail}
            inputType="email"
            onSuccess={(_) => setCaptureComplete(true)}
          />
        </div>
      ) : null}
      <div
        className={cx({
          'flex justify-center mx-auto': captureComplete || layoutAlignment === 'center',
          'flex justify-start': layoutAlignment === 'left',
          'flex justify-end': layoutAlignment === 'right',
          'max-w-275 l:max-w-400': emailCapture?.enabled && !captureComplete,
        })}
      >
        {emailCapture?.enabled && captureComplete ? (
          <Button
            variant={emailCapture.successCta.buttonType}
            size="large"
            className={cx('mt-15 l:mt-19 l:h-45', {
              'w-full': !captureComplete,
            })}
            disabled={captureError.length > 0}
            link={emailCapture.successCta.link}
          >
            {captureComplete ? emailCapture.successCta.link.title : emailCapture.buttonText}
          </Button>
        ) : cta?.link?.title ? (
          cta?.link?.reference?.hash || cta?.link?.rawLinkOverride?.indexOf('#') == 0 ? (
            <ScrollToHash
              className="cursor-pointer w-fit h-fit"
              hash={cta?.link?.reference?.hash || cta?.link?.rawLinkOverride}
            >
              <Button
                variant={cta.buttonType}
                size="large"
                link={cta.link}
                className="mt-20 l:mt-40 btn-hero"
                onClick={() => analytics.trackButtonClick(cta.link.title)}
                bgColor={getColorStyle(ctaColor)}
              >
                {cta.link.title}
              </Button>
            </ScrollToHash>
          ) : (
            <Button
              variant={cta.buttonType}
              size="large"
              link={cta.link}
              className="mt-20 l:mt-40 btn-hero"
              onClick={() => analytics.trackButtonClick(cta.link.title)}
              bgColor={getColorStyle(ctaColor)}
            >
              {cta.link.title}
            </Button>
          )
        ) : null}
      </div>
      {ctaFooter && (
        <div
          className={cx('mt-18 text-13 leading-20', {
              'flex justify-center mx-auto': layoutAlignment === 'center'
            })}
          style={{ color: getColorStyle(ctaFooterColor) ?? undefined }}
        >
          <BlockContent blocks={ctaFooter} serializers={justLinksSerializer} />
        </div>
      )}
    </div>
  )

  function prepareBg() {
    if (bgStyle === BG_STYLE.BG_Gradient) {
      const background = `linear-gradient(${layout.gradientStyle.gradientColors
        .reduce((bg, { colorStop, hexCode }) => {
          if (!bg.length) bg.push(`${layout.gradientStyle.gradientAngle}deg`)
          bg.push(`${hexCode} ${colorStop}`)

          return bg
        }, [])
        .join(',')})`

      return (
        <div
          className="gradient-anim-2 absolute top-0 left-0 w-full h-full"
          style={{
            backgroundImage: background,
            backgroundSize: '100% 300%',
            backgroundPosition: '0% 0%',
            zIndex: '-1',
          }}
        ></div>
      )
    } else if (bgStyle === BG_STYLE.BG_Media) {
      const asset = layout.backgroundMedia
      return (
        <div className="absolute top-0 left-0 w-full h-full" style={{ zIndex: '-1' }}>
          <Media preload={true} media={asset} fill={true} />
        </div>
      )
    }
  }

  function showBadge() {
    if (!centerBadge || !centerBadge.enabled) return null

    const { header, body, primaryColor, secondaryColor, text, textColor } = centerBadge

    const primaryColorHex = getColorStyle(primaryColor)
    const secondaryColorHex = getColorStyle(secondaryColor)
    const textColorHex = getColorStyle(textColor)

    return (
      <>
        <div className="relative w-full l:-mb-40">
          <div className="w-100 h-100 relative mx-auto mb-18">
            <div
              className="absolute w-95 h-95 top-4 left-4 rounded-50"
              style={{ backgroundColor: secondaryColorHex }}
            />

            <div
              className="absolute w-95 h-95 top-0 left-0 text-center text-13 leading-16
              pl-9 pr-7 pt-5 flex justify-center items-center rounded-50"
              style={{ background: primaryColorHex, color: textColorHex }}
            >
              {text}
            </div>
          </div>

          <div className="-mx-45 px-0 mb-15 l:mb-16 text-26 font-medium leading-33.8">{header}</div>

          <div className="-mx-45 -mb-5 l:mb-16 text-16 l:text-20 leading-16 l:leading-26">
            {body}
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="relative">
      <div className="flex flex-col l:flex-row">
        {layout?._type === 'twoUp' &&
          isScreenLarge != null &&
          !(layout?.fullWidthOnDesktop && isScreenLarge) ? (
          <div
            className={cx(
              'relative l:w-1/2 px-15 2xs:px-30 xs:px-45 l:flex flex-col justify-center items-center z-1',
              {
                'pt-30 s:pt-50': !miniHero && !miniHeroHeight,
                'pb-65 s:pb-60': layout?.mobileOrder === 'top' && !miniHero && !miniHeroHeight,
                'pb-40 s:pb-60': layout?.mobileOrder !== 'top' && !miniHero && !miniHeroHeight,
                'l:h-screen--nav-and-promo': !miniHero && layout?._type !== 'twoUp',
                'l:h-466': miniHero,
                'pt-30 pb-30': miniHero && miniHeroHeight,
              }
            )}
            style={{
              background,
              ...(miniHeroHeight && isScreenLarge ? { height: `${miniHeroHeight}px` } : {})
            }}
          >
            {prepareBg()}
            {content}
          </div>
        ) : null}
        <div
          className={cx('', {
            'l:w-1/2': layout?._type === 'twoUp' && !(layout?.fullWidthOnDesktop && isScreenLarge),
            'relative w-full':
              layout?._type === 'fullWidth' ||
              (layout?._type === 'twoUp' && layout?.fullWidthOnDesktop && isScreenLarge),
            '-order-1 l:-order-1': layout?.mobileOrder === 'top' && layout?.desktopOrder === 'left',
            '-order-1 l:order-none':
              layout?.mobileOrder === 'top' && layout?.desktopOrder === 'right',
            'l:-order-1': layout?.mobileOrder === 'bottom' && layout?.desktopOrder === 'left',
            'l:h-screen--nav-and-promo': !miniHero && layout?._type !== 'twoUp',
            'h-195 m:h-265': miniHero && layout?._type === 'fullWidth',
            'l:h-466 xxl:h-full': miniHero,
            'xxl:pt-33p': miniHero && !miniHeroHeight
          })}
          style={
            isScreenLarge && miniHeroHeight
              ? { height: `${miniHeroHeight}px` }
              : {}
          }
        >
          {isScreenLarge ? (
            <Media
              className={cx('hidden l:block', {
                'pt-100p': !miniHero
              })}
              media={largeMedia}
              preload={true}
              alt={largeMedia?.image?.asset?.altText ?? largeMedia?.altText}
              width={!miniHero && !miniHeroHeight ? layout?._type === 'fullWidth' || layout?.fullWidthOnDesktop ? 1728 : 864 : 720}
              height={!miniHero && !miniHeroHeight ? layout?._type === 'fullWidth' || layout?.fullWidthOnDesktop ? 776 : 864 : +miniHeroHeight}
              sizes={`100vw, (min-width: ${screens.l}) 50vw`}
              fill={true}
              imageLoadPriority={imageLoadPriority}
              objectFit="cover"
              useOldImage={layout?._type === 'twoUp' || layout?._type !== 'fullWidth'}
              noTranforms={layout?._type === 'fullWidth' || (layout?.fullWidthOnDesktop && true)}
            />
          ) : (
            <Media
              className="l:hidden"
              media={media}
              preload={true}
              alt={media?.image?.asset?.altText ?? media?.altText}
              width={isScreenMedium ? 768 : 375}
              height={miniHero? (isScreenMedium ? 265 : 195) : 300}
              sizes={`100vw, (min-width: ${screens.l}) 50vw`}
              fill={layout?._type === 'fullWidth' && miniHero}
              objectFit="cover"
              useOldImage={layout?._type === 'twoUp' || layout?._type !== 'fullWidth'}
              imageLoadPriority={imageLoadPriority}
            />
          )}
          {layout?._type === 'fullWidth' ||
            (layout?._type === 'twoUp' && layout?.fullWidthOnDesktop && isScreenLarge) ? (
            <div
              className={cx('flex m:items-center z-1', {
                'absolute inset-0': !!media,
                'justify-center text-center': !layoutAlignment || layoutAlignment === 'center',
                'justify-start text-left l:ml-30 xxl:ml-70 2k:ml-150': layoutAlignment === 'left',
                'justify-end text-right': layoutAlignment === 'right',
                'py-50 px-44': !miniHero,
                'py-40 px-16 m:px-44': miniHero
              })}
            >
              {content}
            </div>
          ) : null}
        </div>
      </div>
      {layout?.isWaveEnabled ? (
        <div className="absolute inset-x-0 bottom-0 z-2">
          <div className="h-25 l:h-60">
            <Wave />
          </div>
          <div className="hidden l:block h-30 bg-white"></div>
        </div>
      ) : null}
    </div>
  )
}
