import { qs } from 'martha'

export default function ScrollToHash({
  children,
  hash,
  className
}) {

  const goToHash = () => {
    const $el = qs(hash?.includes('#') ? hash : `#${hash}`)
    if($el) $el.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <div className={className} onClick={goToHash}>
      { children }
    </div>
  )
}